import React from 'react'
import BookletSummary from './booklet-summary'

const Booklets = ({ booklets }) => {
  const bookletItems = booklets
    .sort((a, b) => a.ordinal - b.ordinal)
    .map(b => {
      return <li key={b.id}><BookletSummary booklet={b} /></li>;
    });

  return (
    <ul>
      {bookletItems}
    </ul>
  );
}

export default Booklets
