import React from 'react'
import Helmet from 'react-helmet'
import BookletsTemplate from '../components/booklets'
import { graphql } from 'gatsby'
import Layout from '../components/layout'

const IndexPage = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title;
  const booklets = data.allContentfulBooklet.edges.map(({ node }) => node);
  const meta = [
    { name: "description", content: data.site.siteMetadata.description }
  ];
  const page = data.allContentfulPage.edges[0].node;

  return (
    <Layout>
      <div className="post">
        <Helmet title={siteTitle} meta={meta} />
        <h2 className="title">{page.title}</h2>
        <div className="entry" dangerouslySetInnerHTML={{ __html: page.content.childMarkdownRemark.html }}></div>
        <br />
        <BookletsTemplate booklets={booklets} />
      </div>
    </Layout>
  );
}
export default IndexPage

export const siteMetaFragment = graphql`
  fragment siteMeta on Site {
    siteMetadata {
      title
      description
    }
  }`;

export const indexPageQuery = graphql`
  query IndexPageQuery($id: String!) {
    site {
      ...siteMeta
    }
    allContentfulPage(filter: { id: { eq: $id } }) {
      edges {
        node {
          ...pageDetail
        }
      }
    }
    allContentfulBooklet {
      edges {
        node {
          ...bookletSummary
        }
      }
    }
  }`
